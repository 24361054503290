module.exports = {
  // product
  // s3Url: 's3://crm.capichiapp.com',
  // baseUrl: 'https://store.capichiapp.com/',
  // staging
  s3Url: 's3://stg.crm.capichiapp.com',
  baseUrl: 'https://stg.store.capichiapp.com/',
  // dev test
  // s3Url: 's3://dev.crm.capichiapp.com',
  // baseUrl: 'https://dev.store.capichiapp.com/',

  //  dev Mạnh
  // s3Url: 's3://dev.crm.capichiapp.com',
  // baseUrl: 'http://13.250.40.91/',
}
